import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles, Theme, createMuiTheme } from '@material-ui/core/styles';
import { TextField, Button, FormControl, Box, Grid, MuiThemeProvider, MenuItem } from '@material-ui/core';
import { Page } from '@shared/components/layout';
import { Loader } from '@shared/components/loader';
import { IAppState } from '@shared/types';
import { getMonthlySpending } from '@shared/fetch';
import { useSelector } from 'react-redux';
import { CustomMessage } from '@shared/components/custom-message';
import { Toast } from '@shared/components/toast';
import { ResponsiveBar } from '@nivo/bar';
import { formatMoney, unCamelCase } from '@shared/helpers';
import { DatePicker } from '@shared/components/inputs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { startOfYear, endOfDay } from 'date-fns';
import { useWindowSize, useMedia } from 'react-use';

const CHART_COLORS = ['#0773BB', '#73C167', '#C4E0F9', '#1B512D', '#696047', '#4E434A', '#031D44', '#F29E4C', '#E75A7C', '#EE6352'];

interface ProjectSpending {
  projectId: string;
  projectName: string;
  amount: number;
}

interface MonthlySpending {
  monthAndYear: string;
  projectSpendingList: ProjectSpending[];
}

export const SpendHistory = () => {
  const { width } = useWindowSize();
  const classes = useStyles();
  const { selectedClient } = useSelector((state: IAppState) => ({ ...state.extranet }));
  const [startDate, setStartDate] = useState<any>(startOfYear(new Date()));
  const [endDate, setEndDate] = useState<any>(endOfDay(new Date()));
  const [isLoading, setLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<number | string | null>('All Projects');
  const [monthlySpending, setMonthlySpending] = useState<any>([]);
  const [monthlyBudgetGraphMax, setMonthlyBudgetGraphMax] = useState<number>();
  const [chartKeys, setChartKeys] = useState<string[]>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [allChartInfo, setAllChartInfo] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const isTablet = useMedia('(max-width: 960px)');
  const isMobile = useMedia('(max-width: 900px)');

  const fetchMonthlySpend = async () => {
    if (selectedClient) {
      setMonthlySpending([]);
      setAllChartInfo([]);
      setChartKeys([]);
      setLoading(true);
      try {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const formattedStartDate = start.toISOString().substring(0, 10);
        const formattedEndDate = end.toISOString().substring(0, 10);

        if (formattedStartDate > formattedEndDate) {
          setErrorMessage('Start date must come before end date');
          setAllChartInfo([]);
          return;
        }

        const selectProjectToUse = selectedProject === 'All Projects' || selectedProject === 'All Active Projects' ? undefined : selectedProject;
        const allActiveProjects = selectedProject === 'All Active Projects' ? true : null;
        const res = await getMonthlySpending(selectedClient.clientId, formattedStartDate, formattedEndDate, selectProjectToUse, allActiveProjects);

        const amountForChart = res.monthlyProjectSpending.map((item: any) => {
          const combinedObject = item.projectSpendingList.reduce((result: any, item: any) => {
            return result + item.amount;
          }, 0);
          return combinedObject;
        });

        const maxValue = Math.max(...amountForChart);
        setMonthlyBudgetGraphMax(Math.round(maxValue + maxValue * 0.15));

        const monthlyDataForChart = res.monthlyProjectSpending.map((item: any) => {
          const combinedObject = item.projectSpendingList.reduce((result: any, item: any) => {
            return { ...result, [item.projectName]: item.amount };
          }, {});
          combinedObject.monthAndYear = item.monthAndYear;
          return combinedObject;
        });

        let projectInfo = res.projectLookup;
        projectInfo = projectInfo.filter((obj: any) => obj.projectId !== 'All Projects');

        // If All Active Projects is selected, get unique projects from monthly spending data
        if (selectedProject === 'All Active Projects') {
          const activeProjects = new Map(
            res.monthlyProjectSpending
              .flatMap((month: MonthlySpending) => month.projectSpendingList)
              .map((project: ProjectSpending) => [
                project.projectName,
                {
                  projectId: project.projectId,
                  projectName: project.projectName
                }
              ])
          );
          projectInfo = Array.from(activeProjects.values());
        }

        let colorIndex = 0;
        projectInfo.forEach(function (obj: any) {
          obj.color = CHART_COLORS[colorIndex];
          colorIndex >= CHART_COLORS.length - 1 ? (colorIndex = 0) : (colorIndex += 1);
        });

        setAllChartInfo(projectInfo);
        let selectedProjectInfo = projectInfo.find((item: any) => item.projectId === selectedProject);
        const projectNames = projectInfo.map((project: any) => project.projectName);
        const projectColors = projectInfo.map((project: any) => project.color);

        if (selectedProject && selectedProject !== 'All Projects' && selectedProject !== 'All Active Projects') {
          setAllChartInfo([selectedProjectInfo]);
          setChartKeys([selectedProjectInfo?.projectName]);
          setChartColors([selectedProjectInfo?.color]);
        } else {
          setChartKeys(projectNames);
          setChartColors(projectColors);
        }
        setMonthlySpending(monthlyDataForChart);
        let projectList = res?.projectLookup;
        projectList.unshift({ projectId: 'All Projects', projectName: 'All Projects' });
        projectList.unshift({ projectId: 'All Active Projects', projectName: 'All Active Projects' });
        setProjects(projectList);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMonthlySpend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  useEffect(() => {
    setSelectedProject('All Projects');
    fetchMonthlySpend();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);
  return (
    <Page title='Spend History' overflow={false} flexGrow={false} setHeight={false}>
      <Grid container alignItems='center'>
        <Grid container alignItems='center' item xs={12}>
          <Grid container alignItems='center' item xs={12} md={9}>
            <div className={classes.startDate}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.root}
                  label='Start Date'
                  inputVariant='outlined'
                  value={startDate}
                  autoOk={true}
                  okLabel={''}
                  clearLabel={''}
                  onChange={newValue => setStartDate(newValue)}
                  id='start-date'
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.endDate}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  className={classes.root}
                  label='End Date'
                  inputVariant='outlined'
                  value={endDate}
                  autoOk={true}
                  okLabel={''}
                  clearLabel={''}
                  onChange={date => {
                    setEndDate(date);
                  }}
                  id='end-date'
                />
              </MuiPickersUtilsProvider>
            </div>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.dateSearch}>
                <Button color='primary' variant='contained' disabled={!startDate || !endDate} onClick={() => fetchMonthlySpend()}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid container alignItems='center' justify={isTablet ? 'flex-start' : 'flex-end'} item xs={12} md={3}>
            {projects && (
              <Grid item xs={9}>
                <div className={classes.mobileSelector}>
                  <FormControl>
                    <MuiThemeProvider theme={SelectTheme}>
                      <TextField
                        fullWidth
                        select
                        variant='outlined'
                        size='small'
                        id='client-project-select'
                        label='Select Project'
                        value={selectedProject ? selectedProject : ''}
                        onChange={e => {
                          setSelectedProject(e.target.value);
                        }}
                        disabled={isLoading}
                      >
                        {projects?.map((project: any) => (
                          <MenuItem key={project.projectId} value={project.projectId}>
                            {project.projectName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </MuiThemeProvider>
                  </FormControl>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>

        {isLoading && (
          <div className={classes.loader}>
            <Loader type='inline' position='centered' />
          </div>
        )}

        <Grid className={allChartInfo.length > 6 ? classes.chartWrapperManyLegends : classes.chartWrapper} container alignItems='center' item xs={12}>
          {allChartInfo.length === 0 && !isLoading && (
            <div className={classes.noProjectsMessage}>
              <CustomMessage mode='info' messages={[`No project data during this timeframe`]} />
            </div>
          )}
          {allChartInfo.length > 0 && !isLoading && (
            <div className={classes.chartContainer} key={`${width}`}>
              <ResponsiveBar
                theme={{
                  //@ts-ignore
                  fontSize: 14
                }}
                margin={{ top: 30, left: 75, bottom: 50, right: 25 }}
                data={monthlySpending}
                maxValue={monthlyBudgetGraphMax}
                padding={0.5}
                keys={chartKeys}
                indexBy='monthAndYear'
                valueScale={{ type: 'linear' }}
                colors={chartColors}
                enableLabel={false}
                axisBottom={isMobile ? null : { legend: '' }}
                axisLeft={{ legend: '', legendOffset: -60, legendPosition: 'middle', format: d => `${formatMoney(d, 0)}` }}
                tooltip={chartData => {
                  return chartData.value > 0 ? (
                    <>
                      {unCamelCase(chartData.id.toString())}: {formatMoney(chartData.value, 0)}, {chartData.indexValue}
                    </>
                  ) : null;
                }}
              />
              <Grid container alignItems='center' spacing={1} className={classes.barchartlegend}>
                {allChartInfo.map(
                  //@ts-ignore
                  (key, index) => (
                    <Fragment key={`${index}`}>
                      <div className={classes.legendItem}>
                        <Box height='20px' width='20px' mr='.5rem' style={{ backgroundColor: key?.color }} />
                        <span>{key?.projectName}</span>
                      </div>
                    </Fragment>
                  )
                )}
              </Grid>
            </div>
          )}
        </Grid>

        <Toast id='new-request-error' message={errorMessage} open={!!errorMessage} onClose={() => setErrorMessage('')} variant='error' />
      </Grid>
    </Page>
  );
};

const SelectTheme = createMuiTheme({
  overrides: {
    MuiSelect: {
      select: {
        fontFamily: 'Poppins-Light, sans-serif',
        '&:focus, &:active': {
          background: '#fff'
        }
      }
    }
  }
});

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        padding: '15, 5, 15, 0'
      },
      '& .MuiInputBase-input': {
        paddingTop: 10.5,
        paddingBottom: 10.5
      }
    }
  },
  chartWrapper: {
    marginBottom: '25px'
  },
  chartWrapperManyLegends: {
    marginBottom: '25px',
    '@media (max-width: 856px)': {
      marginBottom: '100px'
    },
    '@media (max-width: 750px)': {
      marginBottom: '275px'
    }
  },
  loader: {
    marginTop: 100,
    width: '100%',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginTop: 25
    }
  },
  noProjectsMessage: {
    marginTop: 50,
    width: '100%',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      marginTop: 25
    }
  },
  datePickerStart: {
    minWidth: 160
  },
  startDate: {
    '@media (min-width: 600px)': {
      marginTop: -8
    },
    '@media (max-width: 600px)': {
      paddingRight: 25
    }
  },
  endDate: {
    marginTop: -8,
    '@media (min-width: 600px)': {
      marginLeft: 16
    },
    '@media (max-width: 600px)': {
      paddingRight: 25
    }
  },
  datePickerEnd: {
    minWidth: 160
  },
  requestPage: {
    paddingBottom: '2rem'
  },
  dateSearch: {
    '@media (min-width: 1045px)': {
      marginLeft: 16
    }
  },
  chartContainer: {
    height: '600px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(6),
    '@media (max-width: 768px)': {
      width: '95%'
    }
  },
  barchartlegend: {
    marginRight: '2rem',
    marginLeft: '4rem',
    marginTop: 0,
    paddingBottom: theme.spacing(4),
    fontSize: theme.typography.body1.fontSize
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  projectOption: {
    cursor: 'pointer',
    fontSize: 16,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16
  },
  mobileSelector: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 960px)': {
      marginTop: -8
    },
    '@media (max-width: 959px)': {
      marginTop: theme.spacing(1),
      marginLeft: 0
    }
  },
  inputLoader: {
    marginRight: theme.spacing(1.5),
    marginTop: 4
  }
}));
